import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';
import Error from '../UI/Error';

const EducationPage = ({ error }) => {
    // Error
    if (error) return <Error content={error} />;

    // Show Listing
    return (
        <div>
            <Row className="pt-4 pt-sm-0">
                <Col sm="12">
                    <h1>Education</h1>
                </Col>
            </Row>
            <hr />
            <Row className="pt-md-2">
                <Col xs="12" md="12" className="pt-3 pt-md-0">
                    <h3 style={{marginBottom: 5}}>
                        <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/cuhk_logo.png?alt=media&token=c299936f-2192-43a5-8e49-562ed4430d86" width="40" style={{paddingBottom: 5, marginRight: 5}} alt="CUHK" />
                        The Chinese University of Hong Kong
                    </h3>
                    <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Hong Kong</h6>
                    <h6 style={{color: "gray", whiteSpace: "pre"}}>|   September 2016 - Now</h6>
                    <br />
                    <p>
                        Bachelor student studying in Computer Science.
                    </p>
                    <p>
                        Courses Taken:
                    </p>
                    <ul>
                        <li>CSCI1130 - Introduction to Computing Using Java</li>
                        <li>CSCI2100 - Data Structure</li>
                        <li>CSCI3100 - Software Engineering</li>
                        <li>CSCI3130 - Formal Language and Automata Theory</li>
                        <li>CSCI3150 - Introduction to Operating System</li>
                        <li>CSCI3160 - Design and Analysis of Algorithm</li>
                        <li>CSCI3180 - Principles of Programming Language</li>
                        <li>CSCI4140 - Open-source Software Project Development</li>
                        <li>CSCI4180 - Introduction to Cloud Computation and Storage</li>
                        <li>CSCI4430 - Data Communication and Computer Networks</li>
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row className="pt-md-2">
                <Col xs="12" md="12" className="pt-3 pt-md-0">
                    <h3 style={{marginBottom: 5}}>
                        <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/dtu_logo.png?alt=media&token=da52268e-c165-4ec0-b8ee-c2d46a0c8ace" width="37" style={{paddingBottom: 5, marginRight: 5, marginLeft: 3}} alt="DTU" />
                        Technical University of Denmark
                    </h3>
                    <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Copenhagen</h6>
                    <h6 style={{color: "gray", whiteSpace: "pre"}}>|   January 2019 - May 2019</h6>
                    <br />
                    <p>
                        Exchange student in the second term of my third year in the university.
                    </p>
                    <p>
                        Courses Taken:
                    </p>
                    <ul>
                        <li>02170 - Database System</li>
                        <li>02180 - Introduction to Artificial Intelligence</li>
                        <li>02450 - Introduction to Machine Learning and Data Mining</li>
                    </ul>
                </Col>
            </Row>
            <hr />
            <Row className="pt-md-2">
                <Col xs="12" md="12" className="pt-3 pt-md-0">
                    <h3 style={{marginBottom: 5}}>
                        <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/tcfsh_logo.png?alt=media&token=6127819d-4a22-432e-97d4-374924ca96b4" width="34" style={{paddingBottom: 5, marginRight: 8, marginLeft: 3}} alt="TCFSH" />
                        Taichung First Senior High School
                    </h3>
                    <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Taichung</h6>
                    <h6 style={{color: "gray", whiteSpace: "pre"}}>|   Sepetember 2013 - July 2016</h6>
                    <br />
                    <p>
                        This was my high school.
                    </p>
                </Col>
            </Row>
        </div>
    );
};

EducationPage.propTypes = {
    error: PropTypes.string,
};

EducationPage.defaultProps = {
    error: null,
};

export default EducationPage;
