import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Collapse,
  Navbar,
  NavbarToggler,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Config from '../../../constants/config';
import { SidebarNavItems } from './Sidebar';

class Header extends Component {
  static propTypes = {
    member: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
  }

  static defaultProps = {
    member: {},
  }

  constructor(props) {
    super(props);

    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.state = { isOpen: false };
  }

  onLogout = () => {
    const { logout, history } = this.props;
    logout().then(() => history.push('/login'));
  }

  toggleDropDown = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <header>
        <Navbar dark color="primary" expand="sm" className="fixed-top">
          <Link to="/" className="navbar-brand" style={{ color: '#5A4D4C', paddingRight: 20 }}>
            {Config.appName}
          </Link>
          <div>
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/javierli0829" className="btn btn-primary" style={{borderWidth: 1, borderColor: "#5A4D4C", borderRadius: 10, padding: 0, marginRight: 3}}>
              <i className="icon-social-github" style={{paddingLeft: 5.5, paddingRight: 5.5}} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/chia-chun-javier-li-935657150/" className="btn btn-primary" style={{borderWidth: 1, borderColor: "#5A4D4C", borderRadius: 10, padding: 0, marginRight: 3}}>
              <i className="icon-social-linkedin" style={{paddingLeft: 5.5, paddingRight: 5.5}} />
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hellojavier_/" className="btn btn-primary" style={{borderWidth: 1, borderColor: "#5A4D4C", borderRadius: 10, padding: 0, marginRight: 3}}>
              <i className="icon-social-instagram" style={{paddingLeft: 5.7, paddingRight: 5.7}} />
            </a>
          </div>

          <NavbarToggler onClick={this.toggleDropDown} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <div className="d-block d-sm-none" onClick={this.toggleDropDown}>
                {SidebarNavItems()}
              </div>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

export default withRouter(Header);
