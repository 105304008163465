import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'reactstrap';
import Error from '../UI/Error';

const ExperiencePage = ({ error }) => {
  // Error
  if (error) return <Error content={error} />;

  // Show Listing
  return (
    <div>
      <Row className="pt-4 pt-sm-0">
        <Col sm="12">
          <h1>Experience</h1>
        </Col>
      </Row>
      <hr />
      <Row className="pt-md-2">
        <Col xs="12" md="10" className="pt-3 pt-md-0">
          <h3 style={{ marginBottom: 5 }}>
            Intern, Software Engineer at Skyscanner
          </h3>
          <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Shenzhen</h6>
          <h6 style={{ color: "gray", whiteSpace: "pre" }}>|   June 2019 - September 2019</h6>
          <p style={{ textAlign: "justify" }}>
            <br />
            It was a 14-week internship as a software engineer. 
            I was responsible for feature developments and improvements. 
            Apart from fixing bugs, I was given the chance to make many critical changes in our product independently. 
            For example, our team was assigned to some of the seasonal goals and I was in charge of one of them. 
            To achieve those goals, we did many preparations and discussions to seek possible solutions and to find the most appropriate one. 
            Through the process, I could deeply realize how a task was set and completed and what role a software engineer played in a project. 
            During my internship period, I learned a lot from my colleagues, which includes not only the <strong> developing disciplines</strong> but also the importance of <strong>being a good team player</strong>.
            <br />
            <br />
            Even though my time staying in Skyscanner was not quite long, there are many opportunities for me to try different things, such as <strong>technical sharings, charity activities, and team buildings</strong>. 
            With these experiences, my summer became so fruitful and my overall skills got enhanced.
            <br />
            <br />
            - Enhanced skills of problem solving and solution optimization. 
            <br />
            - Cultivated communication skills inter- and intra-teams.
          </p>
          <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/skyscanner.jpeg?alt=media&token=fd8ec9b6-2dcf-4b91-9f23-d1d3d9a0a4cc" width="80%" alt="Skyscanner" />
        </Col>
      </Row>
      <hr />
      <Row className="pt-md-2">
        <Col xs="12" md="10" className="pt-3 pt-md-0">
          <h3 style={{ marginBottom: 5 }}>
            Intern at Autohome Inc.
          </h3>
          <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Beijing</h6>
          <h6 style={{ color: "gray", whiteSpace: "pre" }}>|   June 2018 - July 2018</h6>
          <p style={{ textAlign: "justify" }}>
            <br />
            It was a 6-week internship in Operation Department. 
            I was responsible for communication with customers and the management of documents. 
            Because this internship is relatively short, the main purpose of is to see what my skills can contribute to the society and to experience the working phenomenon in a company.
            <br />
            <br />
            - Enhanced communcation skills through talking with people with various backgrounds. 
            <br />
            - Dealed with emergencies depending on different situations. 
          </p>
          <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/autohome.jpeg?alt=media&token=c801fdb2-377c-47d8-a014-02ce5f1522ab" width="80%" alt="Autohome" />
        </Col>
      </Row>
      <hr />
      <Row className="pt-md-2">
        <Col xs="12" md="10" className="pt-3 pt-md-0">
          <h3 style={{ marginBottom: 5 }}>
            Global Volunteer at Aiesec
          </h3>
          <h6 style={{ color: "gray", margin: 0, whiteSpace: "pre" }}>|   Wroclaw</h6>
          <h6 style={{ color: "gray", whiteSpace: "pre" }}>|   July 2017 - August 2017</h6>
          <p style={{ textAlign: "justify" }}>
            <br />
            It was a 6-week global volunteer held by Aiesec in Wroclaw, Poland. 
            We did several cultural exchanges with local kids. 
            During this period, I met people from all over the world. We not only prepared for activities for children but also shared our values and insights on different issues and tried to <strong>seek common ground while receiving differences</strong>. 
            It was a good experience for me to cooperate with people holding various opinions. 
            <br />
            <br />
            - Cultivated time management skills through activity scheduling. 
            <br />
            - Enhanced the ability to work with people with different cultural and academic backgrounds. 
          </p>
          <img className="img-fluid" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/aiesec.jpeg?alt=media&token=2c04741c-7fd3-46c4-934e-0cd348047480" width="80%" alt="Aiesec" />
        </Col>
      </Row>
    </div>
  );
};

ExperiencePage.propTypes = {
  error: PropTypes.string,
};

ExperiencePage.defaultProps = {
  error: null,
};

export default ExperiencePage;
