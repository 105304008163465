import React from 'react';
import { Row, Col } from 'reactstrap';

const currentMonth = new Date().getMonth();
const currentYear = new Date().getFullYear();
let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const Footer = () => (
  <footer className="mt-5">
    <Row>
      <Col sm="12" className="text-right pt-3">
        <p>{monthNames[currentMonth] + ' ' + currentYear}</p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
