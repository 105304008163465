import React from 'react';
import { Row, Col } from 'reactstrap';

const About = () => (
  <div>
      <Row className="pt-4 pt-sm-0">
        <Col sm="12">
          <h1>Home</h1>
        </Col>
      </Row>
    <hr />
    <Row className="pt-md-2">
      <Col xs="5" sm="3" lg="2" className="offset-lg-2">
        <img className="img-fluid rounded-circle" src="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/profilepic.jpeg?alt=media&token=6654b482-a2f1-43d4-9076-afe324321204" alt="Javier LI" />
      </Col>
      <Col xs="12" sm="8" lg="5" className="pt-4 pt-sm-0">
        <h3>
          LI Chia Chun (Javier)
        </h3>
        <p style={{margin: 0, fontSize: 14}}>
          <i className="icon-phone" /> : +852 52100091 / +86 13716051952 / +886 972341766
        </p>
        <p style={{margin: 0, fontSize: 14}}>
          <i className="icon-envelope" /> : javierli@link.cuhk.edu.hk
        </p>
        <p style={{margin: 0, fontSize: 14}}>
          <i className="icon-social-linkedin" /> : Chia-Chun (Javier) Li
        </p>
      </Col>
    </Row>
    <hr />
    <Row className="pt-md-2">
      <Col xs="12" md="12" className="pt-3 pt-md-0">
        <h3>
          About me
        </h3>
        <p style={{textAlign: "justify"}}>
          Hello World, I am Javier. Welcome to my website. 
          <br />
          <br />
          I graduated from The Chinese University of Hong Kong in 2020. Currently, I am actively looking for a graduate position.
          Interest not limited to: <strong>Software development, Data analysis, Machine learning. </strong>
        </p>
      </Col>
    </Row>
  </div>
);

export default About;
