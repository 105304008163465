import React from 'react';
import { Col } from 'reactstrap';

const DownloadBlock = ({displayName, downloadName, link}) => (
    <Col xs="12" md="4" className="pt-md-0" style={{borderStyle: "solid", borderWidth: "2px", borderRadius: "0.75rem", height: "2.4rem"}}>
        <a href={link} download={downloadName} target="_blank" rel="noopener noreferrer" style={{display: "block", height: "100%", width: "100%", fontSize: "1.2rem", textAlign: "center"}}>
            {displayName}
        </a>
    </Col>
);

export default DownloadBlock;
