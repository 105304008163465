/* global window */
import React from 'react';
import { Col, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';

const SidebarNavItems = () => (
  <div>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname === '/' && 'active'}`} to="/" onClick={window.scrollTo(0, 0)}>
        <i className="icon-home" />
        {' '}
        <span>Home</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/education') && 'active'}`} to="/education" onClick={window.scrollTo(0, 0)}>
        <i className="icon-graduation" />
        {' '}
        <span>Education</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/experience') && 'active'}`} to="/experience" onClick={window.scrollTo(0, 0)}>
        <i className="icon-briefcase" />
        {' '}
        <span>Experience</span>
      </Link>
    </NavItem>
    <NavItem>
      <Link className={`nav-link ${window.location.pathname.startsWith('/documents') && 'active'}`} to="/documents" onClick={window.scrollTo(0, 0)}>
        <i className="icon-folder-alt" />
        {' '}
        <span>Documents</span>
      </Link>
    </NavItem>
  </div>
);

const Sidebar = () => (
  <div>
    <Col sm="3" md="2" className="d-none d-sm-block sidebar">
      <Nav vertical>
        {SidebarNavItems()}
      </Nav>
    </Col>
  </div>
);

export { Sidebar, SidebarNavItems };
