import React from 'react';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
} from 'reactstrap';
import Error from '../UI/Error';
import DownloadBlock from '../UI/DownloadBlock';

const DocumentsPage = ({ error }) => {
    // Error
    if (error) return <Error content={error} />;

    // Show Listing
    return (
        <div>
            <Row className="pt-4 pt-sm-0">
                <Col sm="12">
                    <h1>Documents</h1>
                </Col>
            </Row>
            <hr />
            <Row className="pt-md-2">
                <DownloadBlock displayName="Resume" downloadName="JavierResume.pdf" link="https://firebasestorage.googleapis.com/v0/b/reactnativestarterjavier.appspot.com/o/CV_v2_20200720.pdf?alt=media&token=e6d652cb-37dc-4085-9ed5-a6bea5fc4651"/>
            </Row>
        </div>
    );
};

DocumentsPage.propTypes = {
    error: PropTypes.string,
};

DocumentsPage.defaultProps = {
    error: null,
};

export default DocumentsPage;
