import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Templates
import TemplateSidebar from '../components/Templates/Sidebar';

// Routes
import Home from '../components/Home';
import ExperiencePage from '../components/Experience/ExperiencePage';
import EducationPage from '../components/Education/EducationPage';
import DocumentsPage from '../components/Documents/DocumentsPage';

import Error from '../components/UI/Error';

const Index = () => (
  <Switch>
    <Route
      exact
      path="/"
      render={props => (
        <TemplateSidebar>
          <Home {...props} />
        </TemplateSidebar>
      )}
    />
    <Route
      exact
      path="/education"
      render={props => (
        <TemplateSidebar>
          <EducationPage {...props} />
        </TemplateSidebar>
      )}
    />
    <Route
      exact
      path="/experience"
      render={props => (
        <TemplateSidebar>
          <ExperiencePage {...props} />
        </TemplateSidebar>
      )}
    />
    <Route
      exact
      path="/documents"
      render={props => (
        <TemplateSidebar>
          <DocumentsPage {...props} />
        </TemplateSidebar>
      )}
    />
    <Route
      render={props => (
        <TemplateSidebar pageTitle="404 - Page not found">
          <Error {...props} title="404" content="Sorry, the route you requested does not exist" />
        </TemplateSidebar>
      )}
    />
  </Switch>
);

export default Index;
